import { Box, Button, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { Modal } from '../../../../components'
import { usePeerGroups } from '../../../../providers/PeerGroups/PeerGroups.provider'
import { PeerGroupSession } from '../../../../providers/PeerGroups/types'

interface AddSessionMediaModalProps {
  setShowAddSessionMediaModal: React.Dispatch<React.SetStateAction<boolean>>
  showAddSessionMediaModal: boolean
}

export const AddSessionMediaModal = ({
  showAddSessionMediaModal,
  setShowAddSessionMediaModal,
}: AddSessionMediaModalProps) => {
  const {
    peerGroupDetails,
    addMediaToSinglePeerGroupSession,
    getPeerGroupDetails,
  } = usePeerGroups()

  const [selectedSessionId, setSelectedSessionId] = useState('')
  const [selectedSession, setSelectedSession] = useState<PeerGroupSession>({
    id: 0,
    start: '',
    end: '',
    mediaIds: [],
  })

  const [checkedMedia, setCheckedMedia] = useState<number[]>([])

  const handleSelectedMedia = (media: number) => {
    setCheckedMedia((prevCheckedMedias) => {
      if (prevCheckedMedias.includes(media)) {
        return prevCheckedMedias.filter((i) => i !== media)
      } else {
        return [...prevCheckedMedias, media]
      }
    })
  }

  useEffect(() => {
    setSelectedSessionId(String(peerGroupDetails?.sessions[0].id))
  }, [peerGroupDetails])

  useEffect(() => {
    const matchingSession = peerGroupDetails?.sessions.find(
      (session) => session.id === Number(selectedSessionId)
    )
    if (matchingSession) {
      setSelectedSession(matchingSession)
    }
  }, [selectedSessionId])

  useEffect(() => {
    setCheckedMedia(selectedSession.mediaIds)
  }, [selectedSession])

  const handleSave = async () => {
    if (checkedMedia.length > 0) {
      await addMediaToSinglePeerGroupSession(
        Number(selectedSessionId),
        checkedMedia
      )
      await getPeerGroupDetails(peerGroupDetails?.peerGroupId)
    }
    setShowAddSessionMediaModal(false)
  }

  return (
    <Modal
      open={showAddSessionMediaModal}
      setOpen={setShowAddSessionMediaModal}
      title={'ADD MEDIA TO PEER GROUP SESSIONS'}
      width='40%'
      ModalContent={
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'space-between',
            justifyContent: 'space-between',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: 'max-content',
              alignSelf: 'center',
            }}
          >
            <h3 style={{ marginRight: '15px' }}>Session Date: </h3>
            <select onChange={(e) => setSelectedSessionId(e.target.value)}>
              {peerGroupDetails?.sessions.map((session) => {
                return (
                  <option key={session.id} value={session.id}>
                    {dayjs(session.start).format('MM/DD/YYYY hh:mmA')}
                  </option>
                )
              })}
            </select>
          </Box>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'center',
              height: '200px',
              overflow: 'scroll',
              padding: 10,
            }}
          >
            {peerGroupDetails?.media?.map((media, i) => {
              return (
                <Box
                  key={`peer-group-media-${i}`}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    marginBottom: '5px',
                    width: '50%',
                    alignSelf: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '16px',
                      marginBottom: '5px',
                      marginRight: '20px',
                      textAlign: 'left',
                      width: 'max-content',
                    }}
                  >
                    {media.fileName}
                  </Typography>
                  <input
                    type='checkbox'
                    checked={media.id ? checkedMedia.includes(media.id) : false}
                    onChange={() => {
                      console.log('media.id', media.id)
                      if (media.id) {
                        handleSelectedMedia(media.id)
                      }
                    }}
                    className='form-checkbox h-5 w-5 text-blue-600'
                    style={{
                      width: '20px',
                    }}
                  />
                </Box>
              )
            })}
          </div>
          <Button
            variant='contained'
            onClick={() => {
              handleSave()
            }}
            style={{ width: '50px', alignSelf: 'center', marginTop: '10px' }}
          >
            Save
          </Button>
        </Box>
      }
    />
  )
}
