import { Box, Button, Typography } from '@mui/material'
import { useContext, useEffect } from 'react'
import { FieldValues, UseFormReset } from 'react-hook-form'

import { FormInstructions, NavigationLink } from '../../../../components'
import {
  LinearProgressWithLabel,
  Phase,
} from '../../../../components/Progress/ProgressWithLabel'
import {
  PeerGroupsContext,
  PeerGroupsContextInterface,
} from '../../../../providers/PeerGroups/PeerGroups.provider'
import { PeerGroupDetails } from '../../../../providers/PeerGroups/types'
import { useProviderDetails } from '../../../../providers/ProviderDetails/ProviderDetails.provider'
import {
  FieldObjectEditable,
  FieldObjectNonEditable,
} from '../../../../utilities/Forms/FieldObject.partial'
import { Field, SectionFields } from '../../../../utilities/Forms/types'
import { MonthlyOccurrenceField } from '../MonthlyOccurrenceField'
import { ViewType } from '../types'

interface AddUpdateViewProps {
  peerGroupId: number | null
  peerGroupDetails: PeerGroupDetails | null
  peerGroupDetailsEditMode: boolean
  peerGroupDetailFields: SectionFields
  viewMode: number
  setViewMode: React.Dispatch<React.SetStateAction<ViewType>>
  setPeerGroupId: React.Dispatch<React.SetStateAction<number | null>>
  watchedFields: { name: string; value: any }[] | null
  submitting: boolean
  activePhase: Phase
  reset: UseFormReset<FieldValues>
  setShowCancelModalMode: React.Dispatch<React.SetStateAction<boolean>>
  showAddSessionMediaModal?: boolean
  setShowAddSessionMediaModal?: React.Dispatch<React.SetStateAction<boolean>>
}

export const AddUpdateView = ({
  peerGroupDetailsEditMode,
  peerGroupDetailFields,
  viewMode,
  setViewMode,
  setPeerGroupId,
  watchedFields,
  submitting,
  activePhase,
  reset,
  setShowCancelModalMode,
  showAddSessionMediaModal,
  setShowAddSessionMediaModal,
}: AddUpdateViewProps) => {
  const { myProviderDetails } = useProviderDetails()

  useEffect(() => {
    console.log('showaddsessionmediamodal', showAddSessionMediaModal)
  }, [showAddSessionMediaModal])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
          paddingLeft: 7,
          paddingRight: 2,
          display: 'flex',
          justifyContent: 'space-around',
          height: '100%',
        }}
      >
        <Box
          id='column-left'
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          {peerGroupDetailFields.map((section) => {
            const frequencyFieldExists = section.fields.some(
              (field) => field.label === 'Frequency'
            )
            return (
              (section?.dependentOn?.key
                ? watchedFields?.find(
                    (x) => x.name === section?.dependentOn?.key
                  )?.value === section?.dependentOn?.being
                : true) && (
                <Box
                  key={section.id || section.sectionLabel}
                  sx={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      marginBottom: 0.5,
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        sx={{ fontWeight: 700, fontSize: 18, marginBottom: 1 }}
                      >
                        {section.sectionLabel}
                      </Typography>
                      {!!section.hasInstructions &&
                        peerGroupDetailsEditMode && <FormInstructions />}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap',
                        height: 'min-content',
                      }}
                    >
                      {section.fields.map((field: Field) => {
                        if (!peerGroupDetailsEditMode) {
                          if (field.label === 'Media') {
                            return (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                  }}
                                >
                                  <FieldObjectNonEditable
                                    field={field}
                                    key={field.label}
                                  />
                                  <Button
                                    variant='contained'
                                    onClick={() => {
                                      if (setShowAddSessionMediaModal) {
                                        setShowAddSessionMediaModal(true)
                                      }
                                    }}
                                  >
                                    Select Media for Peer Group Sessions
                                  </Button>
                                </div>
                              </>
                            )
                          } else {
                            return (
                              <FieldObjectNonEditable
                                field={field}
                                key={field.label}
                              />
                            )
                          }
                        }
                        if (peerGroupDetailsEditMode) {
                          return (
                            <FieldObjectEditable
                              field={field}
                              key={field.label}
                            />
                          )
                        }
                      })}
                      {frequencyFieldExists && (
                        <MonthlyOccurrenceField watchedFields={watchedFields} />
                      )}
                    </Box>
                  </Box>
                </Box>
              )
            )
          })}
          {!peerGroupDetailsEditMode ? (
            <Box sx={{ display: 'flex', margin: '10px' }}>
              <Button
                variant='contained'
                onClick={() => setViewMode(ViewType.Edit)}
                sx={{ marginRight: '10px' }}
              >
                Edit Peer Group
              </Button>
              {myProviderDetails?.accessRoles &&
                (myProviderDetails?.accessRoles[0].description === 'Admin' ||
                  myProviderDetails?.accessRoles[0].description ===
                    'SuperAdmin') && (
                  <Button
                    variant='contained'
                    color='error'
                    onClick={() => setShowCancelModalMode(true)}
                  >
                    Delete Peer Group
                  </Button>
                )}
            </Box>
          ) : (
            <Box sx={{ width: '100%' }}>
              <Box sx={{ display: 'flex', margin: '10px', width: '100%' }}>
                <NavigationLink
                  label='Cancel'
                  direction='backward'
                  handleClick={() => {
                    setPeerGroupId(null)
                    setViewMode(ViewType.List)
                    reset()
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    margin: '10px',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <Button variant='contained' type='submit' sx={{ mr: '10px' }}>
                    Save
                  </Button>
                  {submitting && (
                    <LinearProgressWithLabel progress={activePhase} />
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
