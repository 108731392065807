import { createContext, ReactNode, useContext, useState } from 'react'

import { useAxios } from '../../utilities/Requests/useAxios'
import { getImageUrl } from '../../utilities/Storage'
import {
  MessageData,
  MessagesContextType,
  MessageThreadData,
  MessageUser,
  SendMessageData,
  ThreadsObj,
} from './types'

export const MessagesContext = createContext<MessagesContextType | null>(null)

export const MessagesProvider = ({ children }: { children?: ReactNode }) => {
  const [isNewMessage, setIsNewMessage] = useState(false)

  const [threads, setThreads] = useState<ThreadsObj>({
    myPatients: [],
    providers: [],
    otherPatients: [],
    // NCA-47: Created new Message Tab
    pastPatients: [],
  })

  const [thread, setThread] = useState<MessageThreadData>({
    messageThreadId: 0,
    unread: false,
    messages: [],
    displayUser: {
      name: '',
      role: '',
      photoUrl: '',
      isPatient: false,
      onCareTeam: false,
    },
    displayThreadUserNames: '',
    displayUserCount: 0,
    otherUsers: [],
  })

  // attachments
  const [attachmentKeys, setAttachmentKeys] = useState<
    { id: number; key: string }[]
  >([])

  const { fetch } = useAxios()

  //NCA-47: Modify on API side to create new thread filter "pastPatients"
  const getProviderMessageThreads = async () => {
    const { data, error } = await fetch({
      path: `Message/GetProviderMessageThreads`,
    })
    if (data) {
      for (const property in data) {
        data[property].forEach(async (d: MessageThreadData) => {
          if (d.displayUser.photoUrl) {
            d.displayUser.photoUrl = await getImageUrl(d.displayUser.photoUrl)
          }

          d.messages.forEach(async (m: MessageData) => {
            if (m.fromUser.photoUrl) {
              m.fromUser.photoUrl = await getImageUrl(m.fromUser.photoUrl)
            }
            m.toUsers.forEach(async (t: MessageUser) => {
              if (t.photoUrl) {
                t.photoUrl = await getImageUrl(t.photoUrl)
              }
            })

            const notificationCount = Object.values(threads)
              .flat()
              .filter((x) => x.unread).length

            const notificationTitle = notificationCount
              ? `(${notificationCount}) NorthStar-Care Provider Portal`
              : `NorthStar-Care Provider Portal`
            document.title = notificationTitle
            setThreads({ ...data })
          })
        })
      }

      return data
    } else if (error) {
      throw new Error(`Error in getProviderMessageThreads.`)
    }
  }

  // get all the messages in one thread
  const getProviderMessagesInThread = async (
    threadId: number,
    messageId: number
  ) => {
    const { data, error } = await fetch({
      path: `Message/GetProviderMessagesInThread?messageThreadId=${threadId}&messageId=${messageId}`,
    })
    console.log('data-1', data)
    if (data) {
      data.messages.forEach(async (m: MessageData) => {
        if (m.fromUser.photoUrl) {
          m.fromUser.photoUrl = await getImageUrl(m.fromUser.photoUrl)
        }
        m.toUsers.forEach(async (t: MessageUser) => {
          if (t.photoUrl) {
            t.photoUrl = await getImageUrl(t.photoUrl)
          }
        })
        if (thread.messageThreadId === threadId) {
          setThread((prev) => {
            if (prev.messages[0].fromUser) {
              const newAndOldMessages = [
                ...prev.messages,
                ...data.messages,
              ].reduce((acc, current) => {
                if (
                  !acc.some(
                    (message: MessageData) =>
                      message.messageId === current.messageId
                  )
                ) {
                  acc.push(current)
                }
                return acc
              }, [])

              return {
                ...prev,
                messages: newAndOldMessages.sort(
                  (a: MessageData, b: MessageData) => a.messageId - b.messageId
                ),
              }
            }
            return { ...data }
          })
        } else {
          setThread({ ...data })
        }
      })
    } else if (error) {
      throw new Error(`Error in getProviderMessagesInThread.`)
    }
  }

  const sendMessage = async (sendMessageData: SendMessageData) => {
    const { error } = await fetch({
      path: `Message/SendProviderMessage`,
      methodType: 'POST',
      body: sendMessageData,
    })
    if (error) {
      throw new Error(`Error in sendMessage.`)
    }
  }

  return (
    <MessagesContext.Provider
      value={{
        threads,
        getProviderMessageThreads,
        thread,
        getProviderMessagesInThread,
        sendMessage,
        attachmentKeys,
        setAttachmentKeys,
        isNewMessage,
        setIsNewMessage,
      }}
    >
      {children}
    </MessagesContext.Provider>
  )
}

export const useMessages = () =>
  useContext(MessagesContext) as MessagesContextType
