import { Box, Button, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useContext, useEffect, useState } from 'react'
import { default as _ReactPlayer } from 'react-player/lazy'
import { ReactPlayerProps } from 'react-player/types/lib'

import { FilterSvg } from '../../assets/filter'
import {
  DrinkCounterContext,
  DrinkCounterContextInterface,
} from '../../providers/DrinkCounter/DrinkCounterProvider'
import {
  MUMContext,
  MUMContextInterface,
  MUMRecordObj,
} from '../../providers/MUM/MUMProvider'
import {
  BACContext,
  BACContextInterface,
  BACObj,
} from '../../providers/PatientChartPage/BACProvider'
import { themeColors } from '../../theme'
import { Modal } from '../Modal/Modal'
import { useStyles } from './PatientChartPage.styles'

const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>

interface MyJourneyReviewTableInterface {
  routedPatientId: string | undefined
  selectedReviewOption: string
  ReviewBACTableTabs: string[]
  ReviewMUMTableTabs: string[]
  videoReviewFilter: boolean
  setVideoReviewFilter: React.Dispatch<React.SetStateAction<boolean>>
  filteredBacWithVideos: BACObj[]
  setFilteredBacWithVideos: React.Dispatch<React.SetStateAction<BACObj[]>>
  filteredMUMVideos: MUMRecordObj[]
  setFilteredMUMVideos: React.Dispatch<React.SetStateAction<MUMRecordObj[]>>
}

export const MyJourneyReviewTable = ({
  routedPatientId,
  selectedReviewOption,
  ReviewBACTableTabs,
  ReviewMUMTableTabs,
  videoReviewFilter,
  setVideoReviewFilter,
  filteredBacWithVideos,
  setFilteredBacWithVideos,
  filteredMUMVideos,
  setFilteredMUMVideos,
}: MyJourneyReviewTableInterface) => {
  const { classes } = useStyles()

  const { getBacRecords, reviewBacRecord } = useContext(
    BACContext
  ) as BACContextInterface

  const { patientDrinkTypes, getPatientDrinkTypes, resetPatientDrinkTypes } =
    useContext(DrinkCounterContext) as DrinkCounterContextInterface

  const { getPatientMUMRecords, reviewMumRecord } = useContext(
    MUMContext
  ) as MUMContextInterface

  const [showVideo, setShowVideo] = useState(0)
  const [showReviewModal, setShowReviewModal] = useState(false)

  useEffect(() => {
    getPatientDrinkTypes(Number(routedPatientId))
  }, [])

  const renderModal = (recordId?: number) => (
    <Modal
      open={showReviewModal}
      setOpen={setShowReviewModal}
      title={
        selectedReviewOption === 'Review BAC'
          ? 'Mark as Reviewed'
          : 'Reset Drink Types'
      }
      width={'30%'}
      ModalContent={
        <Box
          sx={{
            width: '92%',
            margin: 'auto',
            padding: '10px 0',
          }}
        >
          <Typography
            sx={{
              fontSize: '20px',
              lineHeight: '25px',
              color: themeColors.grayblack,
              padding: '10px 0',
            }}
          >
            {selectedReviewOption === 'Review BAC' ||
            selectedReviewOption === 'Review MUM'
              ? 'Are you sure you want to mark this video as reviewed?'
              : 'Are you sure to reset drink types for this patient?'}
          </Typography>
          <div className={classes.modalBtnWrapper}>
            <Button
              onClick={async () => {
                if (selectedReviewOption === 'Review BAC' && recordId) {
                  await reviewBacRecord(recordId)
                  if (routedPatientId) {
                    const newData = await getBacRecords(Number(routedPatientId))
                    setShowReviewModal(false)
                    setFilteredBacWithVideos(newData)
                  }
                }
                if (
                  selectedReviewOption === 'Review Drink Types' &&
                  routedPatientId
                ) {
                  await resetPatientDrinkTypes(parseInt(routedPatientId))
                  await getPatientDrinkTypes(parseInt(routedPatientId))
                  setShowReviewModal(false)
                }
                if (selectedReviewOption === 'Review MUM' && recordId) {
                  await reviewMumRecord(recordId)
                  if (routedPatientId) {
                    const newData = await getPatientMUMRecords(
                      Number(routedPatientId)
                    )
                    setShowReviewModal(false)
                    setFilteredMUMVideos(newData)
                  }
                }
              }}
              variant='contained'
              style={{
                fontSize: '16px',
                marginTop: '10px',
                width: '180px',
                backgroundColor: themeColors.red,
                borderColor: 'transparent',
              }}
            >
              {selectedReviewOption === 'Review BAC' ||
              selectedReviewOption === 'Review MUM'
                ? 'Mark as Reviewed'
                : 'Reset Drink Types'}
            </Button>
            <Button
              onClick={() => {
                setShowReviewModal(false)
              }}
              variant='contained'
              style={{
                fontSize: '16px',
                marginTop: '10px',
                width: '80px',
              }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      }
    />
  )

  const renderBacMumTable = (tabs: string[], recordVideos: any[]) => {
    return (
      <table className={classes.reviewTable}>
        <caption className={classes.tableCaption}>
          Click Date to view video recording.
        </caption>
        <thead className={classes.tableThead}>
          <tr>
            {tabs.map((r) => {
              return (
                <th
                  className={classes.tableLabelTh}
                  scope='col'
                  key={`ReviewTable-${r}`}
                >
                  {r === 'Reviewed' ? (
                    <button
                      type='button'
                      className={classes.tableSortBtn}
                      onClick={() => {
                        setVideoReviewFilter(!videoReviewFilter)
                      }}
                    >
                      <span className={classes.tableSortTxt}>{r}</span>
                      <FilterSvg />
                    </button>
                  ) : (
                    r
                  )}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {recordVideos?.map((r, i) => {
            return (
              <>
                <tr key={`table-video-${i}`}>
                  <th className={classes.tableTh} scope='col'>
                    {r.reviewed ? (
                      dayjs(r.recordDate).format('MM/DD/YYYY')
                    ) : (
                      <button
                        type='button'
                        onClick={() => {
                          showVideo === r.id
                            ? setShowVideo(0)
                            : setShowVideo(r.id)
                        }}
                        className={classes.videoLinkBtn}
                      >
                        {dayjs(r.recordDate).format('MM/DD/YYYY')}
                      </button>
                    )}
                  </th>
                  <th className={classes.tableTh} scope='col'>
                    {dayjs(r.recordDate).format('h:mm:ss A')}
                  </th>
                  {selectedReviewOption === 'Review BAC' ? (
                    <th className={classes.tableTh} scope='col'>
                      {r.value}
                    </th>
                  ) : null}
                  <th className={classes.tableTh} scope='col'>
                    {r.reviewed ? 'Reviewed' : 'Needs Review'}
                  </th>
                  <th className={classes.tableTh} scope='col'>
                    {r.reviewed ? r.reviewedBy : 'None'}
                  </th>
                </tr>
                {showVideo === r.id && r.videoURL && !r.reviewed && (
                  <div className={classes.playerWrapper}>
                    <ReactPlayer
                      controls
                      className='react-player'
                      url={r.videoURL}
                      width='100%'
                      height='80%'
                    />
                    <Button
                      onClick={() => {
                        setShowReviewModal(true)
                      }}
                      variant='contained'
                      style={{
                        fontSize: '16px',
                        marginTop: '10px',
                        width: '180px',
                        backgroundColor: themeColors.red,
                        borderColor: 'transparent',
                      }}
                    >
                      Mark as Reviewed
                    </Button>
                    {renderModal(r.id)}
                  </div>
                )}
              </>
            )
          })}
        </tbody>
      </table>
    )
  }

  if (
    selectedReviewOption === 'Review MUM' &&
    filteredMUMVideos.some((v) => v.videoURL)
  ) {
    return renderBacMumTable(ReviewMUMTableTabs, filteredMUMVideos)
  }
  if (
    selectedReviewOption === 'Review BAC' &&
    filteredBacWithVideos?.some((v) => v.videoURL)
  ) {
    return renderBacMumTable(ReviewBACTableTabs, filteredBacWithVideos)
  }
  if (
    selectedReviewOption === 'Review Drink Types' &&
    patientDrinkTypes?.some((r) => r.name)
  ) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <table className={classes.reviewTable}>
          <thead className={classes.tableThead}>
            <tr>
              <th className={classes.tableLabelTh} scope='col'>
                Drink types
              </th>
              <th className={classes.tableLabelTh} scope='col'>
                Alcohol Content (%)
              </th>
              <th className={classes.tableLabelTh} scope='col'>
                Drink Ounces
              </th>
            </tr>
          </thead>
          <tbody>
            {patientDrinkTypes?.map((t, i) => {
              return (
                <tr key={`table-drinkType-${i}`}>
                  <th className={classes.tableTh} scope='col'>
                    {t.name}
                  </th>
                  <th className={classes.tableTh} scope='col'>
                    {t.alcoholContent}
                  </th>
                  <th className={classes.tableTh} scope='col'>
                    {t.drinkOunces}
                  </th>
                </tr>
              )
            })}
            {renderModal()}
          </tbody>
        </table>
        <Button
          onClick={() => {
            setShowReviewModal(true)
          }}
          variant='contained'
          style={{
            fontSize: '16px',
            marginTop: '10px',
            width: '170px',
            backgroundColor: themeColors.red,
            borderColor: 'transparent',
            borderRadius: '5px',
            alignSelf: 'flex-end',
          }}
        >
          Reset Drink Types
        </Button>
      </div>
    )
  } else {
    return (
      <span className={classes.tableCaption}>
        This patient has no record yet.
      </span>
    )
  }
}
