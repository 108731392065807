import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { themeColors } from '../../theme'

export const useStyles = makeStyles()((theme: Theme) => ({
  patientChartContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  TopSectionView: {
    padding: '20px 10px',
    borderRadius: '10px',
    boxShadow: '1px 3px 4px palette.boxShadow',
    margin: '10px 0',
  },
  TabView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    padding: '0 10px',
  },
  EachTabView: {
    borderBottomColor: themeColors.blue40,
    borderBottomWidth: '4px',
    cursor: 'pointer',
  },
  EachTabSelectedView: {
    borderBottomColor: themeColors.blue40,
    borderBottomWidth: '4px',
    cursor: 'pointer',
  },
  TabTxt: {
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'center',
    marginBottom: '2px',
  },

  patientChartInfoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    border: 'solid 2px lightgrey',
    borderRadius: '5px',
    padding: '20px 10px',
    backgroundColor: themeColors.white,
    boxShadow: '0px 5px 10px -4px rgba(0, 0, 0, 0.25)',
    minWidth: '600px',
    overflow: 'scroll',
  },

  patientChartInfoCol: {
    marginRight: '20px',
  },

  patientData: {
    border: 'solid 2px lightgrey',
    borderRadius: '3px',
  },

  patientChartDataContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },

  patientChartDataLeft: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    marginRight: 30,
  },

  patientGraphContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 0',
    backgroundColor: themeColors.white,
    borderRadius: '5px',
    border: 'solid 2px lightgrey',
    padding: '20px',
    boxShadow: '0px 5px 10px -4px rgba(0, 0, 0, 0.25)',
    minWidth: '600px',
    overflow: 'scroll',
  },

  graphTabs: {
    margin: '0.5rem 0',
    borderBottom: '1px solid',
    borderBottomColor: themeColors.gray90,
    padding: '10px 0',
    width: '100%',
  },

  graphTabButton: {
    border: 'none',
    backgroundColor: 'transparent',
    margin: '0 20px',
    padding: '5px 10px',
    cursor: 'pointer',
    '&:hover': {
      background: themeColors.darkBlue,
      color: themeColors.grayWhite,
      borderRadius: 5,
    },
  },

  graphTabButtonTxt: {
    fontWeight: 700,
    fontSize: '18px',
    fontFamily: theme.typography.fontFamily,
  },

  graphTabButtonSelected: {
    border: 'none',
    margin: '0 20px',
    padding: '5px 10px',
    background: themeColors.darkBlue,
    color: themeColors.grayWhite,
    borderRadius: 5,
  },

  chart: {
    margin: '10px 0',
  },

  // chart styles
  timeRangeTabs: {
    marginBottom: '20px',
    width: '100%',
  },

  timeRangeTabBtn: {
    border: 'none',
    backgroundColor: 'transparent',
    margin: '0 40px',
    padding: '5px 10px',
    '&:hover': {
      background: themeColors.grayWhite,
      color: themeColors.darkBlue,
    },
  },

  timeRangeTabBtnTxt: {
    fontWeight: 700,
    fontSize: '16px',
    fontFamily: theme.typography.fontFamily,
  },

  timeRangeTabBtnSelected: {
    border: 'none',
    margin: '0 40px',
    padding: '5px 10px',
    background: themeColors.grayWhite,
    color: themeColors.darkBlue,
  },

  // list styles
  recordListWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'wrap',
    margin: '5px 0 20px 0',
    minWidth: '600px',
    overflow: 'scroll',
  },

  recordItem: {
    margin: 10,
    width: 'max-content',
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: theme.typography.fontFamily,
  },

  recordItemTime: {
    fontSize: '16px',
    fontFamily: theme.typography.fontFamily,
    marginRight: 10,
  },

  recordItemResult: {
    fontWeight: 700,
    fontSize: '16px',
    fontFamily: theme.typography.fontFamily,
  },

  // BAC/MUM
  patientChartDataRight: {
    width: '48%',
    height: 'max-content',
    margin: '20px 0',
    backgroundColor: themeColors.white,
    borderRadius: '5px',
    border: 'solid 2px lightgrey',
    padding: '20px',
    boxShadow: '0px 5px 10px -4px rgba(0, 0, 0, 0.25)',
    minWidth: '600px',
    overflow: 'scroll',
  },

  reviewTable: {
    borderCollapse: 'collapse',
    border: '2px solid',
    borderColor: themeColors.gray90,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    letterSpacing: '1px',
    width: '100%',
    padding: '10px',
  },

  tableCaption: {
    margin: '15px 0',
    fontSize: '18px',
  },

  tableThead: {
    backgroundColor: themeColors.gray90,
    marginTop: 10,
  },

  tableLabelTh: {
    border: 'solid 2px',
    borderColor: themeColors.gray90,
    textAlign: 'left',
    padding: '0 5px',
  },

  videoLinkBtn: {
    fontSize: '16px',
    color: themeColors.blue,
    padding: '0',
    letterSpacing: '1px',
    textAlign: 'center',
    alignSelf: 'center',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    width: '100px',
  },

  tableSortBtn: {
    border: 'none',
    borderRadius: '5px',
    backgroundColor: 'transparent',
    padding: '10px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      backgroundColor: themeColors.grayWhite,
    },
  },

  tableSortTxt: {
    margin: '0 10px',
  },

  tableTh: {
    border: 'solid 2px',
    borderColor: themeColors.gray90,
    textAlign: 'left',
    padding: '10px 12px',
  },

  reviewBtn: {
    fontSize: '16px',
    color: themeColors.blue,
    padding: '0',
    letterSpacing: '1px',
    textAlign: 'center',
    alignSelf: 'center',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: themeColors.grayWhite,
    },
  },

  playerWrapper: {
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  modalBtnWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))
